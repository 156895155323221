import { createWebHashHistory, createWebHistory, createRouter } from "vue-router";
import { $session } from "dot-framework";
import routerLogin from "@/routes/login.js";
import routerTest from "@/routes/test.js";
import routerExternal from "@/routes/external.js";
import global from "global";
import UAParser from "ua-parser-js";

const isDesktopOrUnknownDevice = () => {
  const parser = new UAParser();
  const device = parser.getDevice();

  // Possible 'device.type': console, mobile, tablet, smarttv, wearable, embedded
  return typeof device === "object" && typeof device?.type === "undefined";
}
const routes = [
  {
    name: "root",
    path: "/",
    component: () => import(/* webpackChunkName: "main" */ "@/views/main/Index.vue"),
    meta: {
      layout: "DefaultLayout",
      title: "Dot Apps : Home",
    },
    beforeEnter: (to, from, next) => {
      if (global.isKCHFUser()) {
        return next({ name: "kchfMain" });
      }
      next();
    },
  },
  {
    name: "canvas",
    path: "/canvas",
    component: () => import(/* webpackChunkName: "dot-canvas" */ /* webpackPrefetch: true */ "@/views/canvas/DotCanvas.vue"),
    meta: {
      layout: "WideLayout",
      title: "Dot Apps : Dot Canvas",
    },
  },
  {
    name: "book",
    path: "/book",
    alias: "/textbook",
    component: () => import(/* webpackChunkName: "dot-book" */ "@/views/text-book/DotTextBook.vue"),
    meta: {
      layout: "WideLayout",
      title: "Dot Apps : Dot Book",
    },
  },
  {
    name: "cloud",
    path: "/cloud",
    alias: "/drive",
    component: () => import(/* webpackChunkName: "dot-cloud" */ "@/views/drive/DriveList.vue"),
    meta: {
      layout: "DriveLayout",
      title: "Dot Apps : Dot Cloud",
    },
  },
  {
    name: "support",
    path: "/support",
    alias: "/VUE_APP_DOT_SUPPORT_APP",
    beforeEnter: () => window.location.replace(process.env["VUE_APP_DOT_SUPPORT_APP"]),
  },
  {
    name: "appCheckBroker",
    path: "/appCheckBroker",
    meta: {
      layout: "BlankLayout",
      title: "Dot Apps : App Check Broker",
    },
    component: () => import("@/views/AppCheckBroker.vue")
  },
  ...routerLogin,
  ...routerTest,
  ...routerExternal,
];

const whiteList = ["appCheckBroker", "root", "login", "login-callback", "support"];

const router = createRouter({
  history: process.env.VUE_APP_IS_ELECTRON === "true" ? createWebHashHistory() : createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!isDesktopOrUnknownDevice() && to.name !== "appCheckBroker" && from.name !== "appCheckBroker") {
    next({ name: "appCheckBroker" });
  } else {
    if (
      // make sure the user is authenticated
      !$session.isLogin() &&
      // ❗️ Avoid an infinite redirect
      !whiteList.includes(to.name)
    ) {
      // If there is no refresh token, go to login page
      if (!$session.getRefreshToken()) {
        // redirect the user to the login page
        next({name: "login"});
      }

      $session
        .callRefreshToken()
        .then(() => next())
        .catch((error) => {
          // if the refresh token is invalid, go to login page
          if (error.response.status >= 400 && error.response.status < 500) {
            next({
              name: "login",
              query: {redirect: to.fullPath},
            });
          }
        });
    } else {
      next();
    }
  }
});

router.afterEach((to, from) => {
  const path = to.path.split("/").filter(Boolean).join(", ");
  const title = to.meta.title === undefined ? `Dot Apps : ${path}` : to.meta.title;
  document.title = title;
});

export { router };
